import React from "react";
import { Element } from "react-scroll";

import PageWrapper from "../components/PageWrapper";
import SEO from "../components/seo";

import Hero from "../sections/landing1/Hero";
import Works from "../sections/landing1/Works";
import Contact from "../sections/landing1/Contact";
import Copy from "../sections/landing1/Copy";
import CTA from "../sections/landing1/CTARow";

const IndexPage = ({data}) => {
  return (
    <>
      <PageWrapper>
      <SEO title={data.prismicHomepage.data.meta_title.text} description={data.prismicHomepage.data.meta_description.text}/>
        <Hero data={data.prismicHomepage.data}/>
        <Copy data={data.prismicHomepage.data}/>
        {data.prismicHomepage.data.show_bottom_cta === true &&
        <CTA data={data.prismicCta.data}/>
      }
      </PageWrapper>
    </>
  );
};

export const query = graphql`
query HomepageQuery {
  prismicHomepage(internal: {}) {
    id
    data {
      first_sticky_block {
        block_color
        block_text {
          text
          html
        }
        text_color
      }
      first_sticky_group_cta
      first_sticky_group_color
      header_image {
        dimensions {
          width
          height
        }
        url
      }
      first_sticky_group_left_text {
        text
      }
      header_text {
        hero_word_color
        word {
          text
        }
      }
      hero_background_color
      hero_cta_text {
        text
      }
      hero_show_cta
      meta_description
      meta_title
      second_sticky_block {
        block_color
        block_text {
          text
          html
        }
        text_color
      }
      second_sticky_group_color
      second_sticky_group_cta
      second_sticky_group_left_text {
        text
        html
      }
      show_bottom_cta
      subheading_text {
        text
      }
    }
  }
  prismicCta(internal: {}) {
    id
    data {
      copy {
        text
      }
      cta_button {
        text
      }
      cta_image {
        url
      }
    }
  }
}

`

export default IndexPage;
