import React, { useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import GlobalContext from "../../context/GlobalContext";
import { Section, Title, Button, Text } from "../../components/Core";

import styled from "styled-components";
import CTABG from "../../assets/jane/opacity.jpg";




const StyledCTARow = styled(Row)`

min-height: 50vh;
text-align: center;
background-repeat: no-repeat;
background-size: cover, cover;
background-position: center;


p{

  padding-top: 4rem;
  width: 100%;
  font-size: 2rem;
  font-weight: bold;
}

`


const CTA = ({data}) => {

  const gContext = useContext(GlobalContext);

  useEffect(() => {

  }, []);


  return (
    <>
      <StyledCTARow style={{backgroundImage: `url(${data.cta_image.url})`}} className='no-gutters'>
        <Text color='white' className='m-4'>It is time to let go, <br />
disconnect with your past, <br />
finally move forward.<br />
          <br /><br />
I would love to hear from you.<br />
Leave your details and I will give you a call.
</Text>
        <Text>
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              gContext.toggleContact();
              if (gContext.visibleOffCanvas) {
                gContext.toggleOffCanvas();
              }
            }}
          >
            <Button color='#90608A' bg='#DEB6B7' mb={5}>{data.cta_button.text}</Button>

          </a>
        </Text>

      </StyledCTARow>

    </>
  );
};

export default CTA;
