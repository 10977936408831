import React, { useContext } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-scroll";
import { Builder } from '@builder.io/gatsby';
import GlobalContext from "../../context/GlobalContext";

import { Title, Button, Section, Box, Text } from "../../components/Core";

import { device } from "../../utils";
import imgL from "../../assets/jane/jane_portrait.png"

const StyledSection = styled(Section)`

min-height: 100vh;
position: relative;

`

const StyledCol = styled(Col)`
`

const ImgRight = styled.img`
  max-width: 100%;
`;

const Meet = styled.span`
  color: #DCB4B5;
`;

const Your = styled.span`
color: #8E6086;
`;

const Mind = styled.span`
  color: #72989F;
`;


const StyledContainer = styled(Container)`

@media screen and (min-width: 500px) {
position: absolute;
top: 25%;
transform: translateY(-25%);
left: 50%;
transform: translateX(-50%);
}

.mobile{

  display: none;
}

@media screen and (max-width: 500px) {
text-align: center;

.mobile{
  display:block;
}

.desktop{
  display: none;
}
}

`

const Hero = ({ data }) => {
  const gContext = useContext(GlobalContext);

  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledSection hero className="position-relative" bg={data.hero_background_color}>
        <StyledContainer>
          <Row className="align-items-center">

            <StyledCol lg="5" className='order-lg-2'>
              <div className="text-center text-lg-right position-relative">
                <div className="img-main">
                  <ImgRight src={data.header_image.url} alt="" />
                </div>
              </div>
            </StyledCol>
            <Col lg="7" className="mb-5 mb-lg-0">
              <Title variant="hero">
                {data.header_text.map((item, index) => {
                  return (
                    <span style={{ color: item.hero_word_color }}>{item.word.text} </span>
                  )
                })}
              </Title>
              <Box mb="15px" mt="15px" className='mobile'>

                <a
                  href="/#"
                  onClick={(e) => {
                    e.preventDefault();
                    gContext.toggleContact();
                    if (gContext.visibleOffCanvas) {
                      gContext.toggleOffCanvas();
                    }
                  }}
                >
                  <Button color='white' bg='#3C5664'>{data.hero_cta_text.text}</Button>
                </a>
              </Box>

              <Text pt={3}>
                {data.subheading_text.text}
              </Text>

              <Box mt="52px" className='desktop'>
                <a
                  href="/#"
                  onClick={(e) => {
                    e.preventDefault();
                    gContext.toggleContact();
                    if (gContext.visibleOffCanvas) {
                      gContext.toggleOffCanvas();
                    }
                  }}
                >
                  <Button color='white' bg='#3C5664'>{data.hero_cta_text.text}</Button>
                </a>

              </Box>
            </Col>
          </Row>
        </StyledContainer>
      </StyledSection>
    </>
  );
};


export default Hero;
