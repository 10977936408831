import React, { useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import GlobalContext from "../../context/GlobalContext";
import { Section, Title, Button, Text } from "../../components/Core";
import Availability from "../../components/Availability";

import styled from "styled-components";
import CTABG from "../../assets/jane/opacity.jpg";



const StyledSection = styled(Section)`
padding-top: unset;
padding-bottom: unset;
.child {
  min-height: 100vh;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}



div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 0px;
  font-size: 20px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

}

.copy_cta{
  text-align: center;
  display: block;
}

`

const StyledCol = styled(Col)`
scroll-snap-type: mandatory;
scroll-snap-points-y: repeat(100vh);
scroll-snap-type: y mandatory;

`



const Copy = ({ data }) => {

  const gContext = useContext(GlobalContext);
  useEffect(() => {
  }, []);

  return (
    <>
      <StyledSection id='copy' bg={data.first_sticky_group_color}>
        <Row className='no-gutters'>
          <Col xl="6">
            <div className="sticky">
              <div class='copy_cta'>
                <Title color='#DDB7B7'>{data.first_sticky_group_left_text.text}</Title>
                {data.first_sticky_group_cta === true &&
                  <a
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      gContext.toggleContact();
                      if (gContext.visibleOffCanvas) {
                        gContext.toggleOffCanvas();
                      }
                    }}
                  >
                    <Button color='#90608A' bg='#DEB6B7' mt={4}>Let's Talk</Button>
                  </a>
                }
              </div>
            </div>
          </Col>
          <StyledCol xl="6">

            {data.first_sticky_block.map((item, index) => {
              return (
                <div style={{ backgroundColor: item.block_color }} id='t1' className='child bg_one'>
                  <Title color={item.text_color}>
                    {item.block_text.text}
                  </Title>
                </div>
              )
            })}

          </StyledCol>
        </Row>
      </StyledSection>
      <StyledSection id='copy' bg={data.second_sticky_group_color}>
        <Row className='no-gutters'>
          <Col xl="6">
            <div className="sticky bg_four">
              <div class='copy_cta'>
                <Title color='#DDB7B7'>{data.second_sticky_group_left_text.text}</Title>
                {data.second_sticky_group_cta === true &&

                  <a
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      gContext.toggleContact();
                      if (gContext.visibleOffCanvas) {
                        gContext.toggleOffCanvas();
                      }
                    }}
                  >
                    <Button color='#90608A' bg='#DEB6B7' mt={4}>Let's Talk</Button>
                  </a>
                }
              </div>
            </div>
          </Col>
          <StyledCol xl="6">
            {data.second_sticky_block.map((item, index) => {
              return (
                <div style={{ backgroundColor: item.block_color }} id='t1' className='child bg_one'>
                  <div id='t1' className='child bg_one'>
                  <Title style={{whiteSpace: "pre-wrap"}} color={item.text_color}>
                    {item.block_text.text}
                  </Title>
                  </div>
                </div>
              )
            })}
          </StyledCol>
        </Row>
      </StyledSection>

    </>
  );
};

export default Copy;
